<template>
    <div class="home fonts-3" v-bind:style="minHeight">
        <!-- HEADER -->
        <header class="type-4 fixed" v-bind:class="addClass()">
            <div class="wide-container-fluid">
                <div class="row">
                    <div class="col-6 col-sm-2">
                        <router-link to="/" class="logo">
                            <img class="home-logo" src="../assets/image/logo.png" alt=""/>
                            <img class="other-logo" src="../assets/image/logo1.png" alt=""/>
                        </router-link>
                    </div>
                    <div class="col-6 col-sm-10 text-right">

                        <div class="navigation-wrapper">

                            <a class="logo responsive"><img src="../assets/image/logo1.png" alt=""/></a>
                            <div class="button-close d-lg-none" @click="sideNavclose"></div>
                            <div class="navigation-overflow">
                                <nav class="text-left clearfix mb-5">
                                    <ul class="mb-0">

                                        <li @click="clicked">
                                            <router-link to="/">Home</router-link>
                                        </li>

                                        <li @click="clicked">
                                            <router-link to="/about/">Profile</router-link>
                                        </li>

                                        <li class="d-none" @click="clicked">
                                            <router-link to="/portfolio/">Discography</router-link>
                                        </li>

                                        <li @click="clicked">
                                            <router-link to="/offer/">Deliverables</router-link>
                                        </li>

                                        <li @click="clicked">
                                            <router-link to="/contact/">Contact</router-link>
                                        </li>

                                    </ul>
                                </nav>
                                <div class="col-12 col-b30 text-left d-lg-none">
<!--                                    <div class="simple-article light transparent col-b30 col-md-b50">-->
<!--                                        <div class="h4 small">INFORMATION</div>-->
<!--                                        <div class="empty-space col-xs-b10"></div>-->
<!--                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod-->
<!--                                            tempor-->
<!--                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis-->
<!--                                            nostrud.</p>-->
<!--                                    </div>-->
                                    <div class="simple-article light transparent col-b30 col-md-b50">
                                        <div class="h4 small">CONTACT INFO</div>
                                        <div class="empty-space col-xs-b10"></div>
                                        <p>Email: <a class="mouseover-simple" target="_blank" href="mailto:contacttsvishnu@gmail.com"
                                                     style="color: #fff;"><b>contacttsvishnu@gmail.com</b></a></p>
                                        <p>Phone: <a class="mouseover-simple" href="tel:+918891113007"
                                                     style="color: #fff;"><b>+91 8891 113 007</b></a></p>
                                    </div>
                                    <div class="h4 small light">FOLLOW ME</div>
                                    <div class="empty-space col-b15"></div>
                                    <div class="follow">
                                        <a href="https://imdb.com/name/nm7837206/?ref_=nmbio_bio_nm" class="entry" target="_blank"><i class="fa fa-imdb"></i></a>
                                        <a href="https://www.facebook.com/TSVishnumusic/" class="entry" target="_blank"><i class="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/tsvishnu/" class="entry" target="_blank"><i class="fa fa-instagram"></i></a>
                                        <a href="https://youtube.com/channel/UCIJEmBBs_M3ALluDiGgyH4A" class="entry" target="_blank"><i class="fa fa-youtube-play"></i></a>
                                        <a href="https://twitter.com/VishnuTS?s=09" class="entry" target="_blank"><i class="fa fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>

                            <!--                            <div class="follow style-1">-->
                            <!--                                <span class="title">Follow me:</span>-->
                            <!--                                <a class="entry" href="#"><i class="fa fa-instagram"></i></a>-->
                            <!--                                <a class="entry" href="#"><i class="fa fa-facebook"></i></a>-->
                            <!--                                <a class="entry" href="#"><i class="fa fa-pinterest-p"></i></a>-->
                            <!--                                <a class="entry" href="#"><i class="fa fa-twitter"></i></a>-->
                            <!--                                <a class="entry" href="#"><i class="fa fa-google-plus"></i></a>-->
                            <!--                            </div>-->

                        </div>

                        <div class="hamburger-icon open-navigation" :class="customClass" @click="sideNav">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>

        <!-- FOOTER -->
        <footer class=" light" v-bind:class="{ 'type-2': $route.path === '/'}">
            <div class="wide-container-fluid">
                <div class="row">
                    <div class="col-md-6 text-center text-lg-left mb-2 mb-md-0">
                        <div class="copyright">
                            <div class="simple-article small grey">
                                &copy; {{ new Date().getFullYear() }} All rights reserved.
<!--                                <div class="developed-by">Developed by <a-->
<!--                                    href="http://xeoscript.com" target="blank">Xeoscript Technologies</a>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="empty-space mb-4 mb-md-0"></div>
                    </div>
                    <div class="col-md-6 text-center text-lg-right">
                        <div class="follow style-1">
                            <span class="title">Follow me:</span>
                            <a href="https://imdb.com/name/nm7837206/?ref_=nmbio_bio_nm" class="entry" target="_blank"><i class="fa fa-imdb"></i></a>
                            <a href="https://www.facebook.com/TSVishnumusic/" class="entry" target="_blank"><i class="fa fa-facebook"></i></a>
                            <a href="https://www.instagram.com/tsvishnu/" class="entry" target="_blank"><i class="fa fa-instagram"></i></a>
                            <a href="https://youtube.com/channel/UCIJEmBBs_M3ALluDiGgyH4A" class="entry" target="_blank"><i class="fa fa-youtube-play"></i></a>
                            <a href="https://twitter.com/VishnuTS?s=09" class="entry" target="_blank"><i class="fa fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
export default {
    name: 'BaseLayout',
    metaInfo: {
        titleTemplate: 'Music Producer | Composer | Performer',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    data () {
        return {
            customClass: '',
            scrollPost: '',
            scrollPosition: null
        };
    },
    methods: {

        updateScroll () {
            this.scrollPosition = window.scrollY;
        },
        sideNav () {
            const element2 = document.getElementById('mobMenuoverlay');

            if (this.customClass === 'active') {
                this.customClass = '';
                document.body.classList.remove('open-menu');
                // document.body.classList.remove('overflow-hidden');
                element2.classList.remove('active');
            } else {
                this.customClass = 'active';
                document.body.classList.add('open-menu');
                // document.body.classList.add('overflow-hidden');
                element2.classList.add('active');
            }
        },
        clicked () {
            const element2 = document.getElementById('mobMenuoverlay');

            this.customClass = '';
            document.body.classList.remove('open-menu');
            element2.classList.remove('active');
        },

        sideNavclose () {
            const element3 = document.getElementById('mobMenuoverlay');

            if (this.customClass === 'active') {
                this.customClass = '';
                document.body.classList.remove('open-menu');
                element3.classList.remove('active');
            }
        },
        addClass () {
            const path = this.$route.path !== '/' && this.$route.path !== '/portfolio/';
            if (path) {
                if (this.scrollPosition > 50) {
                    return ['light', 'scrolled'];
                } else {
                    return ['light'];
                }
            } else {
                if (this.scrollPosition > 50) {
                    return ['scrolled'];
                } else {
                    return [''];
                }
            }
        }
    },
    mounted () {
        window.addEventListener('scroll', this.updateScroll);
    }
};
</script>

<style>

</style>
